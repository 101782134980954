import { schedule_types_ids } from "constants/schedule";
import React from "react";
import { getAuthData } from "utils/AuthUtil";

export const DRIP_MODE = {
  EMAIL: 1,
  WHATSAPP: 2,
};

export const DRIP_AUDIENCE_TYPE = {
  CUSTOMERS: 1,
  LEADS: 2,
};

export const EMAIL_DRIP_STATUS = {
  DRAFT: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  PAUSED: 4,
  DELETED: 5,
};

export const DRIP_USER_TYPE = {
  REJECTED: 1,
  SEGMENTS: 2,
  ALL_LEADS: 3,
  ALL_CUSTOMERS: 4,
  BOOKING_LISTING: 5,
  SUBSCRIPTION_LISTING: 6,
  EXTRAS: 7,
  HIGH_INTENT_LEADS: 8,
  LOW_INTENT_LEADS: 9,
  SLOT_LISTING: 10,

  // temp front end keys to make states (backend structured differently)
  FRONT_END_KEY_CUSTOMER_WITH_SPECIFIC_OFFERING: 11,
  FRON_END_KEY_HIGH_INTENT_WITH_SPECIFIC_OFFERING: 12,
};

export const getCustomerUserType = (offeringType) =>
  ({
    [schedule_types_ids.workshop]: DRIP_USER_TYPE.SLOT_LISTING,
    [schedule_types_ids.one_on_one_appointment]: DRIP_USER_TYPE.SLOT_LISTING,
    [schedule_types_ids.group_class]: DRIP_USER_TYPE.SUBSCRIPTION_LISTING,
    [schedule_types_ids.rolling_class]: DRIP_USER_TYPE.SUBSCRIPTION_LISTING,
  }[offeringType] || DRIP_USER_TYPE.BOOKING_LISTING);

export const HIGH_INTENT_LEAD_SUB_CATEGORIES = [
  DRIP_USER_TYPE.HIGH_INTENT_LEADS,
  DRIP_USER_TYPE.FRON_END_KEY_HIGH_INTENT_WITH_SPECIFIC_OFFERING,
];

export const DRIP_TRIGGERS = {
  MAKING_PURCHASE: 1,
  START_OF_OFFERING: 2,
  PAYMENT_ABANDONMENT: 3,
  GET_IN_TOUCH_FORM: 4,
};

export const DRIP_TRIGGER_LABELS = {
  MAKING_PURCHASE: "When they make a purchase",
  START_OF_OFFERING: "When the offering starts",
  PAYMENT_ABANDONMENT: "When they abandon the payment",
  GET_IN_TOUCH_FORM: "When they fill the get in touch form",
};

export const DRIP_TRIGGERS_MESSAGES = {
  [DRIP_TRIGGERS.MAKING_PURCHASE]: DRIP_TRIGGER_LABELS.MAKING_PURCHASE,
  [DRIP_TRIGGERS.START_OF_OFFERING]: DRIP_TRIGGER_LABELS.START_OF_OFFERING,
  [DRIP_TRIGGERS.PAYMENT_ABANDONMENT]: DRIP_TRIGGER_LABELS.PAYMENT_ABANDONMENT,
  [DRIP_TRIGGERS.GET_IN_TOUCH_FORM]: DRIP_TRIGGER_LABELS.GET_IN_TOUCH_FORM,
};

export const DRIP_CUSTOMER_RADIO_OPTIONS = [
  {
    label: "All customers",
    value: DRIP_USER_TYPE.ALL_CUSTOMERS,
  },
  {
    label: "Customers of a specific offering",
    value: DRIP_USER_TYPE.FRONT_END_KEY_CUSTOMER_WITH_SPECIFIC_OFFERING,
  },
];

/*
Here in user type if creator selects Customers of
specific listing and listing is of type Batch/Rolling
Class then send value 6, otherwise send value 5
 */

export const DRIP_FREQUENCY = {
  MINUTES: 1,
  HOURS: 2,
  DAYS: 3,
  SESSIONS: 4,
};

export const DRIP_FREQUENCY_LABEL = {
  [DRIP_FREQUENCY.MINUTES]: "minutes",
  [DRIP_FREQUENCY.HOURS]: "hours",
  [DRIP_FREQUENCY.DAYS]: "days",
  [DRIP_FREQUENCY.SESSIONS]: "sessions",
};

export const DRIP_FREQUNCY_DROPDOWN_OPTIONS = [
  {
    id: DRIP_FREQUENCY.HOURS,
    label: DRIP_FREQUENCY_LABEL[DRIP_FREQUENCY.HOURS],
    value: DRIP_FREQUENCY.HOURS,
  },
  {
    id: DRIP_FREQUENCY.DAYS,
    label: DRIP_FREQUENCY_LABEL[DRIP_FREQUENCY.DAYS],
    value: DRIP_FREQUENCY.DAYS,
  },
];

export const EMAIL_SCHEDULE = {
  AFTER_REGISTRATION: 1,
  BEFORE_EVENT: 2,
  AFTER_EVENT: 3,
  IMMEDIATELY: 4,
};

export const emailDirpFormKeys = {
  addingNewEmail: "addingNewEmail",
};

export const dripReviewFormKeys = {
  isNameAccordionOpen: "isNameAccordionOpen",
  isSenderDetailAccordionOpen: "isSenderDetailAccordionOpen",
  isSubscribeTriggerAccordionOpen: "isSubscribeTriggerAccordionOpen",
  isUnsubscribeTriggerAccordionOpen: "isUnsubscribeTriggerAccordionOpen",
  isDripFlowAccordionOpen: "isDripFlowAccordionOpen",
  selectedListingDetails: "selectedListingDetails",
};

export const getRichTextEditorVariables = () => {
  const auth = getAuthData();
  return [
    {
      id: "1",
      label: "Customer-First-Name",
      value: "{{customer_first_name}}",
      previewValue: auth?.first_name || "",
    },
    {
      id: "2",
      label: "Customer-Last-Name",
      value: "{{customer_last_name}}",
      previewValue: auth?.last_name || "",
    },
    {
      id: "3",
      label: "Customer-Phone-Number",
      value: "{{customer_phone_number}}",
      previewValue: auth?.phone_number || "",
    },
    {
      id: "4",
      label: "Customer-Country",
      value: "{{customer_country}}",
      previewValue: auth?.country || "",
    },
  ];
};

export const emailTooltips = {
  opens: (
    <span>
      With a recent update to mail privacy, some email clients, like Apple, no
      longer report <b>opens</b> accurately. Focusing on <b>clicks</b> is a
      better strategy.
    </span>
  ),
  unsubscribedCount: (
    <span>
      An email address is <b>unsubscribed</b> when the recipient indicates they
      no longer wish to receive emails from a sender.
    </span>
  ),
};

export const editEmailFormKeys = {
  isScheduleModalOpen: "isScheduleModalOpen",
  isContentModalOpen: "isContentModalOpen",
  conditionModalConfig: "conditionModalConfig",
  magnitude: "magnitude",
  schedule: "schedule",
  email_frequency_type: "email_frequency_type",
  schedule_time: "schedule_time",
  converted_schedule_in_mins: "converted_schedule_in_mins",
  email_subject: "email_subject",
  email_body: "email_body",
  attachment_data: "attachment_data",
  has_attachments: "has_attachments",
  meta_info: "meta_info",
  isWarningModalOpen: "isWarningModalOpen",
  variables_used: "variables_used",
  hyperlinks_used: "hyperlinks_used",
  campaign_subscription_conditions: "campaign_subscription_conditions",
};

export const EMAIL_SUBJECT_MAX_CHARS = 45;

export const EMPTY_OFFERING_TEXT =
  "You have no live offerings currently. Publish an offering to create drip campaigns.";

export const dripLabels = {
  chooseSchedule:
    "Choose the schedule pattern in which you would like to send all the emails in this drip.",
  sendDripTime:
    "Each email in the drip will be sent out at a fixed time of the day. Please choose the time below.",
};

export const MINUTES_IN_5_DAYS = 5 * 24 * 60;

export const PAST_MAILING_UPPER_LIMIT = -MINUTES_IN_5_DAYS;

export const CUSTOM_BLOCK_SEPARATOR = `<!--template-->`;

export const NO_SELECTED_INDEX = -1;

export const EMAIL_META_INFO_TYPE = {
  BUTTON: "button",
  DISCOUNT: "discount",
  EARLY_BIRD: "earlyBird",
};

export const LINKS_ERROR_MESSAGE = "Links can not be empty";
